<template>
  <div class="main-search">
    <label for="file">
      <input
        id="file"
        class="d-none"
        type="file"
        @change="handleFileChange"
        accept="image/gif, image/jpeg ,image/png"
      />
      <i
        class="iconfont confont icon-zhaoxiangji font-size-20 pointer prefix"
        @click="handlePrefixIcon"
      ></i>
    </label>
    <i
      class="iconfont icon-fangdajing font-size-20 pointer suffix"
      @click="handleSuffixIcon"
    ></i>
    <input
      class="input outline focus"
      type="text"
      :placeholder="$t('TypeTheSerachText')"
      v-model="value"
      @input="handleInput"
      @keyup.enter="handleEnter"
    />
  </div>
</template>

<script>
import upLoadFile from "@/utils/upload_to_cos.js";
export default {
  props: {
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    handlePrefixIcon() {
      // this.$emit("prefix", this.file);
    },
    handleSuffixIcon() {
      this.$emit("suffix", this.value);
    },
    handleInput() {
      this.$emit("input", this.value);
    },
    handleFileChange(e) {
      this.file = e.target.files[0];
      this.$emit("prefix", this.file);
    },
    handleEnter() {
      this.$emit("enter", this.value);
    },
  },
};
</script>

<style lang="scss">
.main-search {
  width: 546px;
  height: 36px;
  line-height: 36px;
  position: relative;
  .input {
    width: 100%;
    height: 100%;
    padding-right: 40px;
    padding-left: 40px;
    border: 1px solid #e2e2e2;
  }
  i {
    position: absolute;
    top: 0px;
  }
  .prefix {
    left: 10px;
  }
  .suffix {
    right: 10px;
  }
}
</style>
