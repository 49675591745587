<template>
  <a-modal
    title="选择群组"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <ul class="group-list w-100 flex flex-col p-h-8">
      <li
        class="group-item flex mr-8 align-center just-space-between hvr-bg-color mb-8 p-8 radius-4"
        :class="g.selected ? 'selected' : null"
        v-for="g in list"
        :key="g.id"
        @click="handleClick(g)"
      >
        <div>
          <img class="avatar radius-4 mr-8" :src="g.src" />
          <span class="text-center">{{ g.nick }}</span>
        </div>
        <i class="iconfont icon-check" v-if="g.selected"></i>
        <i class="iconfont icon-check1" v-else></i>
      </li>
    </ul>
  </a-modal>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cur: null,
    };
  },
  create() {},
  methods: {
    handleClick(g) {
      let selected = g.selected ?? false;
      this.$set(g, "selected", !selected);
      this.cur = g;
    },
    handleOk() {
      this.$emit("ok", this.cur);
      this.close();
    },
    handleCancel() {
      this.close();
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background: #b4b4b410;
  z-index: 999;
}
.group-dialog {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 1000;
}
.group-list {
  height: 400px;
  padding-left: 24px;
  .avatar {
    width: 40px;
    height: 40px;
  }
  .group-item {
    height: 50px;
  }
  .group-item.selected {
    background: var(--hvrBgColor);
  }
}
</style>
