<template>
  <div
    class="
      n-select
      base-radius base-border
      align-center
      flex
      text-info-color
      font-size-14
    "
    :class="{ focus }"
    @mouseenter="handleFocus"
    @mouseleave="hanldeBlur"
    @click="handleClick"
  >
    <div class="placeholder placeholder-color">
      <i class="iconfont" :class="opts[idx].icon"></i>
      <div class="flex-1 flex flex-col" :class="{ selected }">
        <span class="placeholder-text">{{ placeholder }} </span>
        <span class="selected-text">{{ opts[idx].label }}</span>
      </div>
      <i class="iconfont icon-xiajiantou"></i>
    </div>
    <div class="selected"></div>
    <ul class="select-opt__wrap box-shadow radius-4" v-if="show">
      <li
        class="opt-item hvr-bg-color p-h-8"
        v-for="(opt, idx) in opts"
        :key="idx"
        @click.stop="handleClickItem(idx)"
      >
        <i class="iconfont font-size-32 mr-8" :class="opt.icon"></i>
        <div class="flex flex-col">
          <span class="text-color font-size-14 mb-4">{{ opt.label }}</span>
          <span class="font-size-12">{{ opt.desc }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Privacy settings",
    },
  },
  computed: {
    selected() {
      return true;
    },
  },
  data() {
    return {
      focus: false,
      idx: 0,
      show: false,
      opts: [
        // {
        //   label: "公开",
        //   value: 1,
        //   desc: "任何人都能访问",
        //   icon: "icon-gongkai1",
        // },
        {
          label: "Private",
          value: 2,
          desc: "Only memeber read",
          icon: "icon-m-nopublic",
        },
      ],
    };
  },
  methods: {
    handleFocus() {
      this.focus = true;
    },
    hanldeBlur() {
      this.focus = false;
    },
    handleClickItem(idx) {
      this.idx = idx;
      this.show = false;
      this.focus = false;
    },
    handleClick() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
.n-select {
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;
  .selected-text {
    font-size: 12px;
    color: #050505;
  }
  .selected-opt {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .placeholder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > i {
      padding: 8px 8px;
      font-size: 20px;
    }
  }
  .select-opt__wrap {
    width: 100%;
    top: 108%;
    position: absolute;
    z-index: 2;
    background: #fff;
    .opt-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
.n-select.focus {
  border: 1px solid var(--primaryColorV2);
}
.selected {
  .placeholder-text {
    font-size: 10px;
    margin-bottom: 2px;
    padding-top: 4px;
  }
}
</style>