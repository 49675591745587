<template>
  <div
    class="product-card pointer"
    :class="selected ? 'selected' : null"
    @click="handleBodyClick"
  >
    <div class="card-body flex just-center align-center relative">
      <span class="type">{{ typeName }}</span>
      <!-- <i
        class="iconfont check font-size-20"
        :class="selected ? 'icon-check' : 'icon-check1'"
        @click="handleClick"
      >
      </i> -->
      <i
        class="iconfont icon-fenxiang check font-size-20"
        @click.stop="handleClick"
      >
      </i>

      <div class="main-pic">
        <img class="img" :src="src" alt="pic" />
      </div>
    </div>
    <div class="card-info flex flex-col p-22">
      <span class="title font-size-12 mb-6">{{ name }}</span>
      <span class="desc mb-6">{{ desc }}</span>
      <span class="price mb-6">{{ price && `$${price}` }}</span>
    </div>
    <div class="card-footer flex align-center">
      <div
        class="flex flex-1 quickshop just-center align-center"
        @click.stop="handleQuickShop"
      >
        <!-- {{ $t("quickshop") }}  -->
        <span class="text-white label">{{ $t(label) }}</span>
      </div>
      <div
        class="box flex just-center align-center"
        @click.stop="handleClickLike"
        v-if="favicon"
      >
        <i
          class="iconfont icon-shoucangxuanzhong design-is-like"
          v-if="like"
        ></i>
        <i class="iconfont icon-shoucang design-like" v-else></i>
      </div>
      <!-- <div
        class="box flex just-center align-center"
        @click="handleClickLike"
        :class="like ? 'like' : null"
      >
        <svg
          class="icon love"
          t="1633853041553"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4254"
          width="256"
          height="256"
          fill="red"
        >
          <path
            v-if="like"
            d="M171.712 571.648l0.352 0.32 287.904 252.8a64 64 0 0 0 82.912 1.344l296.832-244.544a215.584 215.584 0 1 0-301.824-300.576L512 316.672l-25.888-35.616a215.584 215.584 0 1 0-314.4 290.624zM32 407.584a279.584 279.584 0 0 1 480-194.944 279.584 279.584 0 0 1 480 194.944 278.144 278.144 0 0 1-113.024 224.512l-295.36 243.392a128 128 0 0 1-165.888-2.592L129.984 620.16A278.976 278.976 0 0 1 32 407.584z"
            p-id="4255"
          ></path>
          <path
            v-else
            d="M1024.549 360.609c0-170.492-133.815-309.265-298.055-309.265-81.129 0-157.91 34.998-213.344 94.701-55.509-59.702-132.367-94.701-213.344-94.701C135.49 51.344 1.751 190.041 1.751 360.609c0 5.719 0.534 10.827 0.991 15.021-0.076 1.373-0.152 2.745-0.152 4.194 0 30.193 7.319 63.361 21.73 98.59 0.458 1.295 0.915 2.516 1.449 3.657 90.812 217.844 440.412 468.474 455.279 479.985 9.227 7.092 20.205 10.6 31.263 10.6 11.209 0 22.266-3.659 31.566-10.903 12.733-9.911 310.941-224.551 429.279-427.603 4.498-6.861 7.854-13.494 10.828-19.215 0.914-1.829 1.753-3.658 2.744-5.413l0.382-0.839c0.382-0.686 0.839-1.449 1.296-2.059 7.091-13.802 12.732-26.611 17.232-39.116 12.274-32.177 18.3-60.847 18.3-87.61 0-2.058-0.077-3.888-0.229-5.414C1024.093 370.979 1024.549 366.251 1024.549 360.609z"
            p-id="4597"
          ></path>
        </svg>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "WOMEN",
    },
    label: {
      type: String,
      default: "quickShop",
    },
    desc: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/works/0.394195589679181633750015.132.jpg",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    like: {
      type: Boolean,
      default: false,
    },
    favicon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: this.checked,
    };
  },
  created() {},
  computed: {
    typeName() {
      return this.type == 1 ? "设计图" : "3D";
    },
  },
  methods: {
    handleBodyClick() {
      this.handleQuickShop();
    },
    handleClick() {
      this.selected = !this.selected;
      this.$emit("change", this.id, this.name, this.type, this.src);
    },
    handleQuickShop() {
      this.$emit("bottom", { id: this.id, type: this.type });
    },
    handleClickLike() {
      let t = this.like;
      this.$emit("update:like", !t);
      this.$emit("like", !t);
    },
  },
};
</script>

<style lang="scss">
.product-card {
  width: 234px;
  height: 283px;
  background: #ffffff;
  // border: 1px solid #e2dede;
  border: 1px solid #fff;
  transition: all 0.5s ease;
  display: inline-block;
  .design-is-like {
    color: #ffe356;
  }
  .card-body {
    height: 164px;
    background: #ffffff;
    border-bottom: 1px solid #e2dede;
    padding: 10px 4px;
    opacity: 1;

    .check {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 13px;
      right: 10px;
    }
    .type {
      width: 36px;
      font-size: 12px;
      font-family: STHeitiSC;
      font-weight: 500;
      color: #323131;
      position: absolute;
      top: 16px;
      left: 24px;
    }
    .icon-check {
      color: var(--primary-color-v2);
    }
  }
  .main-pic {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .title {
    font-weight: 600;
    color: #2f2e2e;
  }
  .desc {
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    color: #4d4d4d;
  }
  .price {
    width: 100%;
    font-size: 12px;
    color: #000000;
  }
  .card-info {
    width: 100%;
    height: 86px;
  }
  .card-footer {
    width: 100%;
    height: 32px;
    position: relative;
    .quickshop {
    }

    .label {
    }
    .box {
      width: 28px;
      height: 28px;
      background: #fff;
      position: absolute;
      right: 1px;
      .love {
        width: 20px;
        height: 20px;
        + .like {
          fill: #f34747;
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.09);
    transform: scale(1.02, 1.02);
    border: 1px solid var(--primaryColorV2);
    .card-body {
      border-bottom: 1px solid var(--primaryColorV2);
    }
    // .box {
    //   border-left: 1px solid var(--primaryColorV2);
    //   border-top: 1px solid var(--primaryColorV2);
    // }
    .card-footer {
      background: #21c3a9;
      color: #fff;
      opacity: 1;
      .design-like {
        color: #2c3e50;
      }
    }
  }
}
.product-card.selected {
  border: 1px solid var(--primaryColorV2);
  .card-body {
    border-bottom: 1px solid var(--primaryColorV2);
  }
  .box {
    border-left: 1px solid var(--primaryColorV2);
    border-top: 1px solid var(--primaryColorV2);
  }
}
</style>
