<template>
  <label class="n-input">
    <div
      class="input_wrap placeholder-color"
      :class="{ focus, blur, movedown }"
    >
      <span class="placeholder">{{ placeholder }}</span>
      <input
        class="input"
        type="text"
        @focus="handleFocus"
        @blur="handleBlur"
        autocomplete="off"
        v-model="input"
        @input="handleInput"
      />
    </div>
  </label>
</template>

<script>
export default {
  name: "n-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  data() {
    return {
      focus: false,
      blur: false,
      input: "",
      movedown: false,
    };
  },
  methods: {
    handleFocus() {
      this.focus = true;
      this.movedown = true;
      this.blur = false;
    },
    handleInput() {
      this.$emit("input", this.input);
    },
    handleBlur() {
      if (!this.input) {
        this.focus = false;
        this.movedown = false;
      } else {
        this.blur = true;
      }
    },
  },
};
</script>

<style lang="scss">
.n-input {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 6px;
  .input_wrap {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #ababab;
    border-radius: 6px;
  }
  .input {
    outline: none;
    height: 66%;
    width: 100%;
    text-indent: 16px;
    border: none;
    position: absolute;
    top: 10px;
    background: transparent;
    font-size: 12px;
    color: #050505;
  }
  .placeholder {
    position: absolute;
    height: 30%;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    font-size: 12px;
    transition: all 0.2s ease;
  }
}
.input_wrap.movedown {
  .placeholder {
    top: 4px;
    transform: translate(0, 0);
    font-size: 10px;
  }
  .input {
    top: 14px;
  }
}
.input_wrap.focus {
  border: 1px solid var(--primaryColorV2);
}
.input_wrap.blur {
  border: 1px solid #ababab;
}
</style>