<template>
  <div class="add-group border-right border-left" v-if="visible">
    <div class="tool border-top border-bottom p-v-8">
      <i
        class="iconfont icon-close1 font-size-24 ml-16 hvr-color"
        @click="handleClose"
      ></i>
    </div>
    <div class="mt-12 p-h-13">
      <span>{{ $t("newFolder") }}</span>
    </div>
    <div class="flex align-center p-h-13 text-color p-v-16">
      <img class="avatar mr-12 radius-50" :src="owner.avatar" alt="" />
      <div class="info">
        <div class="nick mb-4 font-size-14">
          <span>{{ owner.nick }}</span>
        </div>
        <div class="font-size-12 text-info-color">
          <span>{{ $t("admin") }}</span>
        </div>
      </div>
    </div>
    <div class="form p-h-13">
      <n-input
        class="mb-16"
        :placeholder="$t('typeFileName')"
        v-model="name"
      ></n-input>
      <n-input
        class="mb-16"
        :placeholder="$t('typeFileDesc')"
        v-model="desc"
      ></n-input>
      <n-select class="mb-16"></n-select>
      <!-- <member-input
        class="mb-16"
        placeholder="小组名称"
        @input="desc"
      ></member-input> -->
      <n-button @click="handleOk">{{ $t("create") }}</n-button>
    </div>
  </div>
</template>

<script>
// import MemberInput from "@/components/v2/member-input/index.vue";
import NInput from "@/components/v2/input/index.vue";
import NButton from "@/components/button/index.vue";
import NSelect from "@/components/v2/select/index.vue";
import api from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("jiyang/user");
export default {
  components: {
    NInput,
    NSelect,
    NButton,
    // MemberInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      owner: {
        nick: "neko",
        avatar: "./avatar2.jpg",
      },
      name: "未命名文件夹",
      desc: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  computed: {
    ...mapState(["userinfo"]),
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    async getUserInfo() {
      let userinfo = await this.$cookie.get("user_info");
      userinfo = JSON.parse(userinfo);
      this.owner = {
        nick: userinfo.enterprise,
        avatar: userinfo.head_img,
      };
    },
    async handleOk() {
      await api.createFolder({
        project_name: this.name,
        desc: this.desc,
        is_private: 1,
        coverpic: "",
      });
      this.handleClose();
      this.$emit("ok");
    },
  },
};
</script>

<style lang="scss">
.add-group {
  width: 222px;
  height: 439px;
  position: fixed;
  top: 223px;
  left: 10px;
  background: #fff;
  z-index: 999;
  .nick {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  .tool {
    width: 100%;
    color: #999;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .form {
    .n-input {
      width: 100%;
      // height: 56px;
      height: 40px;
    }
    .n-select {
      width: 100%;
      // height: 56px;
      height: 40px;
    }
  }
}
</style>
