<template>
  <div class="card-list">
    <a-spin tip="Loading..." :spinning="loading">
      <div class="tool flex w-100">
        <a-select
          class="mr-8"
          dropdownClassName="filter"
          default-value="all"
          style="width: 120px"
          @change="handleChange"
        >
          <a-select-option
            :value="opt.value"
            v-for="(opt, idx) in opts"
            :key="idx"
          >
            {{ opt.label }}
          </a-select-option>
        </a-select>
        <main-search
          @input="handleSearchInput"
          @prefix="handlePrefixClick"
          @suffix="handleSuffixClick"
          @enter="handleEnter"
        ></main-search>
      </div>
      <div v-if="filterProducts.length > 0">
        <div class="product-list">
          <product-card
            v-for="m in filterProducts"
            :id="m.id"
            :key="m.id"
            :name="m.name"
            :src="m.src"
            :desc="m.intro"
            :price="m.price"
            :like.sync="m.like"
            :type="m.type"
            :label="m.label"
            :favicon="m.favicon"
            @bottom="hanldeBottom"
            @like="(t) => handleLike(m, t)"
            @change="handleCardChange"
          ></product-card>
        </div>
        <div class="pagination">
          <a-pagination
            v-model="page.page"
            :total="page.total"
            :pageSize="page.pageSize"
            @change="handlePageChange"
          />
        </div>
      </div>
      <a-empty v-else></a-empty>
    </a-spin>
  </div>
</template>

<script>
import ProductCard from "@/components/card/ProductCard.vue";
import MainSearch from "@/components/main-search/main-search.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapGetters, mapMutations } =
  createNamespacedHelpers("jiyang/project-group");
import api from "@/api";
export default {
  components: {
    MainSearch,
    ProductCard,
  },
  props: {},
  computed: {
    ...mapState(["products", "page", "currentFolder", "loading"]),
    ...mapGetters(["filterProducts"]),
  },
  data() {
    return {
      opts: [
        {
          value: "0",
          label: "all",
        },
        {
          value: "2",
          label: "3d",
        },
        {
          value: "1",
          label: "design",
        },
      ],
      current: 0,
      like: true,
    };
  },
  watch: {
    $route: {
      handler() {
        let { id, type } = this.$route.query;
      },
      deep: true,
    },
  },
  created() {
    let { id, type } = this.$route.query;
    //this.queryProductList();
  },
  methods: {
    ...mapActions(["queryProductList", "search", "searchImage"]),
    ...mapMutations(["setSelectedType"]),
    handlePageChange(page, pageSize) {
      this.queryProductList({ page, pageSize });
    },
    handleChange(val) {
      // console.log(val);
      this.setSelectedType(val);
    },
    async handlePrefixClick(f) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(f);
      fileReader.onload = (s) => {
        const t = s.target.result.slice(0, 20).includes("WEBPVP8");
        if (t) {
          this.$message({
            showClose: true,
            message: "不支持的文件",
            type: "error",
          });
          return;
        }
        this.searchImage(f);
      };
    },

    handleSuffixClick(val) {
      this.search(val);
    },
    handleEnter(val) {
      this.search(val);
    },
    handleSearchInput(val) {
      this.searhInput = val;
    },

    hanldeBottom({ id, type }) {
      if (this.currentFolder == Number.MAX_VALUE) {
        if (type == 2) {
          this.$router.push({
            name: "workspace",
            query: {
              id: id,
            },
          });
        } else {
          this.$router.push({
            name: "draw",
            query: {
              type: 2,
              id: id,
            },
          });
        }
        return;
      }
      this.$router.push({
        name: "productDetail",
        query: {
          id,
          type,
        },
      });
    },
    handleLike(fold, bl) {
      if (!bl) {
        api.delBaseProject({ product_id: fold.id, type: 1 });
      } else {
        api.addBaseProject({ product_id: fold.id, type: 1 });
      }
    },
    handleCardChange(id, name, type, src) {
      if (this.currentFolder == Number.MAX_VALUE) {
        this.$store.commit("callIM", {
          id: id,
          name: name,
          url: src,
          type: type == 2 ? 7 : 8,
        });
      } else {
        this.$store.commit("callIM", {
          id: id,
          name: name,
          url: src,
          type: type,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card-list {
  position: relative;
  .product-card {
    margin-bottom: 0px;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    position: absolute;
    top: 140px;
    left: -124px;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  .pagination {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .tool {
    margin-bottom: 31px;
    padding-right: 20px;
    height: 36px;
    .ant-select-selection {
      border-radius: 0;
    }
    .ant-select-selection--single {
      height: 36px;
    }
  }
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: stretch;
  }
  .ant-empty {
    position: absolute;
    top: 300px;
    left: calc(50% - 143px);
    transform: translate(-50%, -50%);
  }
}
</style>
