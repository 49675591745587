<template>
  <button
    class="
      n-btn
      primary-color-v2-light
      hvr-sweep-to-right
      font-size-14
      text-white
      hvr-cursor
    "
    :style="{ backgroundColor: color }"
    @click="$emit('click')"
  >
    <i class="mr-6 font-size-12" :class="icon" v-if="icon.length > 0"></i>
    <span><slot></slot></span>
  </button>
</template>

<script>
export default {
  name: "n-button",
  props: {
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.n-btn {
  width: 100%;
  height: 36px;
  border: none;
  outline: none;
  overflow: hidden;
}
</style>