<template>
  <div class="folder-list__wrap">
    <div class="label font-700 font-size-12"></div>
    <ul class="folder-list">
      <li
        class="folder-item hvr-bg-color radius-4 mb-8"
        v-for="f in list"
        :key="f.id"
        :class="selected == f.id ? 'selected' : null"
        @click="handleClick(f.id)"
      >
        <i
          class="folder-icon iconfont radius-4 mr-8"
          :class="f.icon || 'icon-wenjianjia1'"
          alt="folder"
        />
        <div class="info flex-1">
          <div class="text-color font-size-12 font-600 mb-2">
            <span class="d-block mb-2">{{ f.title }}</span>
            <span class="placeholder-color d-block font-size-10">{{
              f.desc
            }}</span>
          </div>
          <div class="text-info-color font-size-10">
            {{ format(f.create_time) || "2021-10" }}
          </div>
        </div>
        <i
          style="display: none"
          class="iconfont icon-fenxiang share p-8 hvr-color"
          @click.stop="handleShare(f)"
        ></i>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "jiyang/project-group"
);
import "@/assets/image/folder.png";
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.queryWish();
    this.selected = Number.MAX_VALUE;
    this.setCurrentFolders(this.selected);
    this.queryProductList();
  },
  computed: {},
  methods: {
    ...mapActions(["queryProductList", "queryWish", "queryWishList"]),
    ...mapMutations(["setCurrentFolders", "setShare", "setWish"]),
    handleClick(id) {
      // console.log(id);
      this.selected = id;
      if (id == "wish") {
        //this.queryWish();
        this.setCurrentFolders(id);
        this.queryWishList();
        return;
      }
      // this.selected = id;
      this.setCurrentFolders(id);
      this.queryProductList();
    },
    format(date) {
      if (date) {
        return dayjs(date).format("YYYY-MM-DD");
      }
    },
    handleShare(f) {
      let type = 6;
      let shareid = f.id;
      if (f.id == Number.MAX_VALUE) {
        let user_info = JSON.parse(this.$cookie.get("user_info"));
        shareid = user_info.id;
        type = 6;
      }
      this.$store.commit("callIM", {
        id: shareid,
        name: f.title,
        url: "",
        type: type,
      });
      // console.log("share", f);
      //this.$emit("share", f);
    },
  },
};
</script>

<style lang="scss">
.folder-list__wrap {
  background: #fff;
  .label {
    height: 22px;
    line-height: 48px;
  }
  .folder-list {
    height: 320px;
    overflow: auto;
    background: #fff;
  }
  .folder-item {
    display: flex;
    align-items: center;
    padding: 0 4px;
    .info {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    // .icon {
    //   color: #ffca28;
    // }
    .icon-shoucangxuanzhong {
      // color: #df8019 !important;
      color: #ffe356 !important;
    }
    .folder-icon {
      // width: 40px;
      // height: 40px;
      font-size: 40px;
      color: #ffca28;
    }
    > .share:hover {
      color: var(--primaryColorV2);
    }
  }
  .folder-item.selected {
    background: var(--primaryColor) !important;
    .share {
      color: #fff;
    }
  }
}
.folder-list::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  margin-left: 8px;
}
.folder-list::-webkit-scrollbar {
  background-color: #fff;
}
</style>