<template>
  <div class="group-wrap font-size-14">
    <div class="mb-16 border p-h-13 h-50p line-h-50p font-700">
      {{ $t("mine") }}
    </div>
    <a-spin tip="Loading..." :spinning="loading"
      ><div class="bg-white border group p-h-13">
        <div class="w-100">
          <div
            class="flex title just-space-between align-center p-v-12 font-700"
          >
            <span>{{ $t("folder") }}</span>
            <!-- 齿轮icon -->
            <!-- <i class="iconfont icon-setting font-size-24"></i> -->
          </div>
          <div class="n-search">
            <i class="iconfont icon-fangdajing"></i>
            <input
              class="mb-16 search-input focus radius-16 font-size-14 w-100"
              type="text"
              v-model="search"
              @keyup.enter="handleSearch"
              :placeholder="$t('searchFolder')"
            />
          </div>
          <div class="p-v-8 border-top border-bottom">
            <n-button icon="iconfont icon-ic_add" @click="showAddGroup = true">
              {{ $t("newFolder") }}
            </n-button>
          </div>
        </div>
        <div
          class="flex justify-center empty align-center"
          v-if="folders.length == 0"
        >
          <a-empty />
        </div>
        <group-list :list="folders" v-else @share="handleShare"></group-list>
      </div>
    </a-spin>
    <add-group :visible.sync="showAddGroup" @ok="handleOk"></add-group>
    <group-dialog
      :visible.sync="showGroupDialog"
      :list="groups"
      @ok="handleGroupOk"
    ></group-dialog>
  </div>
</template>

<script>
import GroupDialog from "@/components/dialog/group-dialog.vue";
import NButton from "@/components/button/index.vue";
import GroupList from "@/components/chat-group/group-list/index.vue";
import AddGroup from "../add-group/index";

import api from "@/api";

export default {
  components: {
    NButton,
    GroupList,
    AddGroup,
    GroupDialog,
  },
  data() {
    return {
      search: "",
      showAddGroup: false,
      showGroupDialog: false,
      share: "",
      folders: [],
      loading: false,
      groups: [
        {
          id: 1,
          src: "./avatar2.jpg",
          nick: "老王",
        },
      ],
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    async getProjectList() {
      this.loading = true;
      const res = await api.queryFolders();
      const data = res.data ?? [];
      const m = data.map((r) => ({
        id: r.id,
        title: r.project_name,
        create_time: r.create_time,
      }));
      this.folders = [
        {
          id: "wish",
          title: "Wish",
          type: "wish",
          icon: "icon-shoucangxuanzhong",
        },
        {
          id: Number.MAX_VALUE,
          title: "WorkSpace",
          icon: "",
          type: "bom",
        },
        ...m,
      ];
      this.loading = false;
    },
    async handleSearch() {
      this.loading = true;
      const res = await api.queryFolders(this.search);
      const m = res.data.map((r) => ({
        id: r.id,
        title: r.project_name,
      }));

      if (this.search == "") {
        this.folders = [
          {
            id: "wish",
            title: "Wish",
            type: "wish",
            icon: "icon-shoucangxuanzhong",
          },
          {
            id: Number.MAX_VALUE,
            title: "WorkSpace",
            icon: "",
            type: "bom",
          },
          ...m,
        ];
      } else this.folders = m;
      this.loading = false;
    },
    handleGroupOk(group) {
      // console.log("group", group);
    },
    handleShare(id) {
      this.share = id;
      this.showGroupDialog = true;
    },
    handleOk() {
      this.getProjectList();
    },
  },
};
</script>

<style lang="scss">
.group-wrap {
  width: 222px;
  color: #000000;
  position: fixed;
  // background: #fff;
  .group-label {
    height: 50px;
    line-height: 50px;
    border: 1px solid #e2dede;
    padding: 0 13px;
    margin-bottom: 16px;
    background: #fff;
  }
  .group {
    width: 100%;
    background: #fff;
  }
  .empty {
    height: 400px;
  }
}
.n-search {
  position: relative;
  .search-input {
    height: 32px;
    // border: none;
    outline: none;
    background: #f1f2f6;
    padding: 4px 24px 4px 4px;
    text-indent: 24px;
  }
  > i {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -100%);
  }
}
</style>
