<template>
  <div class="project-group">
    <chat-group @select="handleSelectFolder"></chat-group>
    <card-list :products="products"></card-list>
    <!-- <chat></chat> -->
  </div>
</template>

<script>
import ChatGroup from "@/components/chat-group/index";
import CardList from "./list/CardList";

export default {
  components: {
    ChatGroup,
    CardList,
  },
  data() {
    return {
      loading: false,
      products: [],
    };
  },
  created() {},
  activated() {
    //刷新后要重新进行ajax请求
    // if (!this.$route.meta.isBack || this.isFirstEnter) {
    //   this.gettableData();
    // }
    // this.$route.meta.isBack = false;
    // this.isFirstEnter = false;
  },

  methods: {
    handleSelectFolder() {},
  },
};
</script>

<style lang="scss">
.project-group {
  height: calc(100vh - 52px);
  // overflow: hidden;
  padding: 10px 10px;
  .card-list {
    margin-left: 260px;
  }
}
</style>
