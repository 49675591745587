<template>
  <group></group>
</template>

<script>
import group from "./group/index";

export default {
  components: {
    group,
  },
};
</script>

<style>
</style>